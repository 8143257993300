<template>
  <div>
    <!-- 通信报表 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" >
      <el-form-item>
        <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input placeholder="请输入套餐名称" v-model="queryParams.mealName"></el-input>
      </el-form-item> 
      
      <el-form-item>
        <el-button type="success" icon="el-icon-search" @click="refresh" >搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-link :href="BaseUrl + 'net//admin/com/report/noLogin/export?mergeFlag=1'" class="button_download">
          <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
        </el-link>
      </el-form-item>
      <el-form-item>
        <el-link :href="BaseUrl + 'net//admin/com/report/noLogin/exportDetail?mergeFlag=1'" class="button_download">
          <el-button type="warning" size="small" icon="el-icon-position">导出明细</el-button>
        </el-link>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="success">导出明细</el-button>
      </el-form-item> -->
    </el-form>
    <!-- 添加 -->
    <div class="content-wrap">
      <!--列表-->
      <paged-table
        :data="correspondList"
        :total="correspondTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh">
        >
        <el-table-column prop="schoolName" label="学校" :show-overflow-tooltip="true" v-if="url?false:true">
        </el-table-column>
        <el-table-column prop="mealName" label="套餐名称" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="sellNum" label="销售笔数" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="sellMoney" label="销售金额" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="fxNum" label="分销笔数" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="fxMoney" label="分销金额" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="refundNum" label="退款笔数" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="refundMoney" label="退款金额" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="activeNum" label="激活数量" :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="noActiveNum" label="未激活数量" :show-overflow-tooltip="true">
        </el-table-column>
      </paged-table>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions,mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import datePicker from "@/mixins/datePicker";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import Tinymce from "@/tinymce";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'correspond',
  components: {
    PagedTable,
    BaseUpload,
    AreaTree,
    Tinymce
  },
  mixins: [pageMixin,provinces,download,datePicker],
  data() {
    return {
      url: '',
      visible: false,
      BaseUrl,
      isChecked:false,
      queryParams: {
        mergeFlag: 0,
        pageNum: 1,
        pageSize: 10,
        payType: null,
        mealName: '',
        startDate: '',
        endDate: '',
      },
      pageApi:'comReportGetList',
    };
  },
  computed: {
    ...mapState("correspond", ["correspondList", "correspondTotal"]),
  },
  methods: {
    ...mapActions("correspond",[ "comReportGetList", ]),
    // 图片上传
     handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png') || file.name.includes('gif');
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG/gif 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      callback(isPicture && isLt2M)
    },
    handleQuery(data){
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
        this.queryParams.city = null;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
      }
      this.refresh()
    },
    timeRangeChange(val, index){
        this.gameTime[index].startDate = val ? val[0] : ''
        this.gameTime[index].endDate = val ? val[1] : ''
    },
  },
  created(){
  },
  mounted(){
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
    this.refresh()
  }
};
</script>
